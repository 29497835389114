import React from "react";
import { Link } from "gatsby";
import Img01 from "../../images/propertyDetails/dealer01.png";
import Img02 from "../../images/newsdetails/client02.png";
import "./MemberCard.scss";
import { graphql, useStaticQuery } from "gatsby";

const MemberCard = ({staff, waMsg}) => {

  const data = useStaticQuery(graphql`
    query GetSpecificContactWithID {
      glstrapi {
        team(id: "6256a1cc548ecb00627226f4") {
          Designation
          URL
          Email
          id
          Phone
          Name
          imagetransforms
          languages
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `)

  if (!staff) {
    staff = data.glstrapi.team;
  }

  if (!staff) {
    return null;
  }

  const whatsapp = staff?.whatsapp_no?staff?.whatsapp_no:staff.Phone

  return (
    <>
      <div className="dealer-info ">
        <div className="dealer-wrap">
          <div className="img-wrap zoom">
            <Link to={`/meet-the-team/${staff.URL}/`}>
            <picture>
              <source media="(min-width: 768px)" srcSet={staff?.Image?.url} />
              <img src={staff?.Image?.url} alt="contact" className="show-img"/>
              <img src={staff?.Image?.url} alt="contact" className="hide-img d-none" />
            </picture>
            </Link>
          </div>
          <div className="dealer-right top-right d-md-none">
          <Link to={`/meet-the-team/${staff.URL}/`}>
            <div className="dealer-name">{staff.Name}</div>
          </Link>
            <div className="dealer-position">{staff.Designation}</div>
            <div className="dealer-contact">
              <span className="title">Languages:</span>{staff.languages}
            </div>
          </div>

          <div className="dealer-right bottom-right d-none d-md-flex">
          <Link to={`/meet-the-team/${staff.URL}/`}>
            <div className="dealer-name">{staff.Name}</div>
          </Link>
            <div className="dealer-position">{staff.Designation}</div>
            <Link href={`tel:${staff.Phone}`} className="contact">
              <i className="icon-call"></i>
              {staff.Phone}
            </Link>
            <a href={`https://wa.me/${whatsapp.split(" ").join("")}?text=${waMsg ? waMsg : ""}`} target="_blank" className="contact">
              <i className="icon-whatsapp"></i>
              Click to WhatsApp
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default MemberCard;
