import React from "react";
import { Link } from "gatsby";
import "./AreaguideCard.scss";
import GetGGFXImage from "../common/site/get-ggfx-image";
import GetGatsbyImage from "../common/site/get-gatsby-image";
import GenerateLink from "../common/site/GetLink";
import Slider from "react-slick";
import { Row } from "react-bootstrap";

const AreaguideCard = (props) => {
  const NewsItem = props.newsCard;

  let processedImages = JSON.stringify({});
  if (NewsItem?.imagetransforms?.Tile_Image_Transforms) {
   processedImages = NewsItem.imagetransforms.Tile_Image_Transforms;
  }

  return (
    <React.Fragment>
      <div className="news-card zoom">
        <div className="properties-wrapper">
          <Link to={"/about/living-in-dubai/" + NewsItem.URL + "/"} className="d-inline-block zoom">
          <GetGGFXImage imagename={"areaguides.Tile_Image.tileimg"} imagesources={NewsItem.Tile_Image} fallbackalt={NewsItem.Title} imagetransformresult={processedImages} id={NewsItem.id}/>                  
          </Link>
          {NewsItem.Select_Category ? (
            <span className="sm-text">{NewsItem.Select_Category.Name}</span>
          ) : null}
        </div>
        <div className="about-properties">
          <div className="properties-info">
            <div className="properties-heading">
              <h2>
                <Link to={"/about/living-in-dubai/" + NewsItem.URL + "/"}>{NewsItem.Title}</Link>
              </h2>

              <Link to={"/about/living-in-dubai/" + NewsItem.URL + "/"} className="btn-newscard">View Guide<i className="icon-right"></i></Link>
            </div>
          </div>


          {/* <div className="content-link">`
            {NewsItem.content ? <p>{NewsItem.content}</p> : null}
            {NewsItem.link ? (
              <>
                <div className="description-link">
                  <span className="read-more">
                    {NewsItem.link}
                    <i className="right-more"></i>
                  </span>
                </div>
              </>
            ) : null}
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AreaguideCard;
