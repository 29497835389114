import React from "react"
import { useWindowSize } from "../../hooks/window-size"
import { Container, Row, Col } from "react-bootstrap"
import DetailsTab from "./DetailsTab/DetailsTab"
import PlanTabs from "./PlanTabs/PlanTabs"
import { AnimationOnScroll } from "react-animation-on-scroll"

import "./PlanDetails.scss"

const OffPlanDetails = ({ offplan }) => {
  const [windowWidth] = useWindowSize()
  return (
    <section className="plan-details">
      <Container>
        <Row>
          <Col>
            {windowWidth < 768 && <DetailsTab offplan={offplan} />}
            {windowWidth >= 768 && <PlanTabs offplan={offplan} />}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OffPlanDetails
