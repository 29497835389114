import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useWindowSize } from "../../hooks/window-size"
import { Container, Row, Col } from "react-bootstrap"
import { AnimationOnScroll } from "react-animation-on-scroll"
import Slider from "react-slick"
import Lightbox from "react-image-lightbox"
import "./ImageGallery.scss"

const OffPlanImageGallery = ({ images }) => {
  const [imags, setImages] = useState([])
  const [windowWidth] = useWindowSize()

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }
  // Property images lightbox

  // Property details images lightbox
  const propertyLightImages = images && images.map(img => img.url)
  // Property details images lightbox

  const openLightBox = index => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  useEffect(() => {}, [windowWidth])

  const settings = {
    arrows: true,
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    mobileFirst: true,
    slidesToShow: windowWidth < 992 ? (windowWidth < 768 ? 1 : 2) : 4,
    slidesToScroll: 1,
    rows: windowWidth < 768 ? 1 : 2,
    slidesPerRow: 1,
  }

  return (
    <section className="image-gallery" id="image-gallery">
      <Container>
        <Row>
          <Col>
            <h3>Image Gallery</h3>

            {images.length && images.length > 0 && (
              <div className="img-block">
                <Slider {...settings} className="">
                  {images.map((image, index) => (
                    <div
                      className="img-card img-zoom"
                      onClick={() => {
                        openLightBox(index)
                      }}
                    >
                      <img src={image.url} alt="gallery" />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      {/* Property image lightbox */}
      {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={
            propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
          }
          prevSrc={
            propertyLightImages[
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}
      {/* Property image lightbox */}
    </section>
  )
}

export default OffPlanImageGallery
