import React, { useState } from "react";
import { Link } from "gatsby";
import { Form, Button, Dropdown } from "react-bootstrap";
import Select from 'react-select'
import "./ContactForm.scss";
import ContactForm from "../forms/register-interest";
import { useLocation } from "@reach/router";
const Contact = (props) => {
  const location = useLocation();
  const searchParams = location?.pathname;
  var propertyurl= "";
  if(location && searchParams){
    propertyurl = `${location.origin}${searchParams}`;
  }

  const department = [
    { value: 'Sales ', label: 'Sales ' },
    { value: 'Product', label: 'Product' },
    { value: 'Sales', label: 'Sales' },
  ]
  const value = [
    { value: 'I’m looking to buy', label: 'I’m looking to buy' },
    { value: 'I’m looking to sell', label: 'I’m looking to sell' },
    { value: 'I’m looking to buy', label: 'I’m looking to buy' }
  ]
  return (
    <>
      <div className="form-wraps">
        <ContactForm property_url={propertyurl} email_cc={props?.staff?.Email?props?.staff?.Email:''}/>
      </div>
    </>
  );
};
export default Contact;
