import React from "react"
import { Link } from "gatsby"
import { useWindowSize } from "../../../hooks/window-size"
import "./ButtonGroup.scss"
import { scrollToElement } from "../../common/site/functions"

const OffPlanButtonGroup = ({ contact, offplan }) => {
  const [windowWidth] = useWindowSize()

  const whatsapp = offplan?.Select_Contact?.whatsapp_no?offplan?.Select_Contact?.whatsapp_no:'+971585138750'
  return (
    <div className="button-group">
      {contact && contact.Phone && (
        <Link href={`tel:${contact.Phone}`} className="btn">
          <i className="icon-dialer"></i>
          {windowWidth > 767 ? contact.Phone : "call"}
        </Link>
      )}
      <button
        onClick={() => {
          scrollToElement(document.getElementById("register-interest-form"))
        }}
        className="btn"
      >
        <i className="icon-envelope"></i>
        {windowWidth < 1200 ? "Register Interest" : "Register Your Interest"}
      </button>
      {contact && contact.Phone && (
        <Link
          href={`https://wa.me/${whatsapp.split(" ").join("")}?text=Hi, I came across the Project ${offplan.name} and would like further information`}
          target="_blank"
          className="btn"
        >
          <i className="icon-whatsapp"></i>
          Click to WhatsApp
        </Link>
      )}
    </div>
  )
}

export default OffPlanButtonGroup
