import React from 'react';
import { Link } from 'gatsby';
import { useWindowSize } from '../../hooks/window-size';
import './ButtonGroup.scss'

const ButtonGroup = () => {
	const [windowWidth] = useWindowSize();

	return (
		<div className='button-group'>
			<Link href='tel:971 4 380 6683' className='btn'>
				<i className="icon-dialer"></i>
				{windowWidth > 767 ? '+971 4 380 6683' : 'call'}
			</Link>
			<Link to='/contact/' className='btn'>
				<i className="icon-envelope"></i>
				{windowWidth < 1200 ? 'Register Interest' : 'Register Your Interest'}
			</Link>
			<Link href="https://wa.me/+971585138750" target="_blank" className='btn'>
				<i className="icon-whatsapp"></i>
				Click to WhatsApp
			</Link>
		</div>
	)
}

export default ButtonGroup