import React from "react"
import { Tabs, Tab } from "react-bootstrap"
import "./PlanTabs.scss"
import DetailsTab from "../DetailsTab/DetailsTab"
import { useWindowSize } from "../../../hooks/window-size"
import { scrollToElement } from "../../common/site/functions"

const PlanTabs = ({ offplan }) => {
  const [windowWidth] = useWindowSize()


  const onSelectHandler = key => {
    if (key === "brochure") {
      window.open(offplan.upload_brochure[0].url, "_blank")
    }
    if (key === "floorPlans") {
      window.open(offplan.upload_floorplan[0].url, "_blank")
    }
    if (key === "paymentPlan") {
      const element = document.getElementById("payment-plans")
      scrollToElement(element)
    }
    if (key === "video") {
      const element = document.getElementById("offplan-video-box")
      scrollToElement(element)
    }
    if (key === "gallery") {
      const element = document.getElementById("image-gallery")
      scrollToElement(element)
    }
    if (key === "location") {
      const element = document.getElementById("offplan-map")
      scrollToElement(element)
    }
  }

  return (
    <div className="plan-tabs">
      {windowWidth < 1200 && (
        <div className="more-btn">
          <button type="button">+2 more</button>
        </div>
      )}
      <Tabs
        activeKey="details & description"
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={key => onSelectHandler(key)}
      >
        <Tab eventKey="details & description" title="details & description">
          <DetailsTab offplan={offplan} />
        </Tab>

        {offplan.upload_brochure && offplan.upload_brochure.length > 0 && <Tab eventKey="brochure" title="brochure"></Tab>}
        {offplan.upload_brochure && offplan.upload_brochure.length > 0 && <Tab eventKey="floorPlans" title="floor plans"></Tab>}
        
        <Tab eventKey="paymentPlan" title="payment plan"></Tab>
        <Tab eventKey="video" title="video"></Tab>
        <Tab eventKey="gallery" title="gallery"></Tab>
        <Tab eventKey="location" title="location"></Tab>
      </Tabs>
    </div>
  )
}

export default PlanTabs
