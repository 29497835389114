import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import AeroDesk from "../../images/landing/aero.jpg"
import Car from "../../images/landing/car1800.jpg"
import CarTab from "../../images/landing/car1800.jpg"
import CarDesk from "../../images/landing/car1800.jpg"
import propertyTab02 from "../../images/landing/1802.jpg"
import DownTownDesk from "../../images/landing/1803.png"
import Location01 from "../../images/landing/place01.png"
import Location02 from "../../images/landing/place02.png"
import Location03 from "../../images/landing/place03.png"
import Location04 from "../../images/landing/place04.png"
import Location05 from "../../images/landing/place05.jpg"
import { useWindowSize } from "../../hooks/window-size"
import parse from "html-react-parser"

import AreaguideCard from "../AreaguideCard/AreaguideCard"

import "./Reasons.scss"
import GenerateLink from "../common/site/GetLink"
import Slider from "react-slick"
import ReasonCard from "../OffPlanCard/ReasonCard"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { graphql, Link, useStaticQuery } from "gatsby"

const OtherOffPlan = ({ module, currentOffplan }) => {
  const [windowWidth] = useWindowSize()

  const getOffplans = useStaticQuery(graphql`
    query GetOtherOffPlans {
      glstrapi {
        offplans (where: {publish:true}, sort: "createdAt:DESC")  {
          URL
          id
          location
          developer
          name
          imagetransforms
          Banner_Img {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 500
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `)

  const offplans = getOffplans.glstrapi.offplans

  // exclude the current offplan from the list by id
  const otherOffplans = offplans.filter(
    offplan => offplan.id !== currentOffplan.id
  )

  const dummyBoxesCount = 4 - otherOffplans.length
  const dummyBoxes = dummyBoxesCount > 0 ? [...Array(dummyBoxesCount)].map((_, i) => (
    <div key={i} className="dummy-box"></div>
  )) : null


  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section className="select-reasons other-offplan">
      <div className="select-reasons-wrap">
        <Container>
          <div className="intro">
            <div className="intro-content">
              <h2 className="heading">Other Developments</h2>
              <Link to="/off-plan-properies-in-dubai/" className="btn">
                View All
              </Link>
            </div>
          </div>

          <Row className="areaguide-cards">
            <Slider className="areaguide-slider" {...settings}>
              {otherOffplans.slice(0, 4).map(item => (
                <Col className="small-card">
                  <ReasonCard key={item.id} card={item} />
                </Col>
              ))}
              {dummyBoxesCount > 0 && dummyBoxes}
              
            </Slider>
          </Row>
        </Container>
      </div>
    </section>
  )
}
export default OtherOffPlan
