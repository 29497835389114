import React from "react"
import { Link } from "gatsby"
import "./ReasonCard.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import GenerateLink from "../common/site/GetLink"
import Slider from "react-slick"
import { Row } from "react-bootstrap"
import parse from "html-react-parser"

const ReasonCard = props => {
  const ReasonItem = props.card

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  let processedImages = JSON.stringify({})
  if (ReasonItem?.imagetransforms?.Tile_Image_Transforms) {
    processedImages = ReasonItem.imagetransforms.Tile_Image_Transforms
  }

  return (
    <React.Fragment>
      <div className="news-card ">
        <div className="properties-wrapper zoom">
          {/* <Link to="#" className="d-inline-block zoom"> */}
          {ReasonItem.Banner_Img && (
            <Link
              className="zoom"
              to={`/off-plan-properties-in-dubai/${ReasonItem.URL}/`}
            >
              <GetGatsbyImage
                image={ReasonItem.Banner_Img}
                fallbackalt={ReasonItem.name}
              />
            </Link>
          )}
          {/* </Link> */}
        </div>
        <div className="about-properties">
          <div className="properties-info">
            <div className="properties-heading">
              <Link to={`/off-plan-properties-in-dubai/${ReasonItem.URL}/`}>
                <h2>
                  {ReasonItem.name} by {ReasonItem.developer}
                </h2>
              </Link>

              <p>{ReasonItem.location}</p>
              {/* {parse(ReasonItem.Description)} */}
            </div>
          </div>

          {/* <div className="content-link">`
            {NewsItem.content ? <p>{NewsItem.content}</p> : null}
            {NewsItem.link ? (
              <>
                <div className="description-link">
                  <span className="read-more">
                    {NewsItem.link}
                    <i className="right-more"></i>
                  </span>
                </div>
              </>
            ) : null}
          </div> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReasonCard
