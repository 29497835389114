import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useWindowSize } from "../../hooks/window-size";
import "./Btn.scss";
import { scrollToElement } from "../../components/common/site/functions"

const ButtonGroup = (props) => {
  const [windowWidth] = useWindowSize();

  return (
    <section className="fix-btn details-fixed">
    <Container>
      <Row>
        <Col md={12} className="d-lg-none">
          <div className="btn-groups">
            <a href="tel:+971505369666" className="btn">
              <i className="icon-dialer"></i>
              <span>
                Contact
              </span>
              {/* <span className="d-none d-md-flex">
              +971 58 531 3748
              </span> */}
            </a>              
            <a onClick={() => {scrollToElement(document.getElementById("register-interest-form"))}} className="btn" >
              <i className="icon-envelope"></i>
              <span >
                Viewing
              </span>
              {/* <span className="d-none d-md-flex">
                Request Details
              </span> */}
            </a>
            <a href={`https://wa.me/+971585138750?text=${props.whatsAppMessage}`} target="_blank" className="btn">
              <i className="icon-whatsapp"></i>
              <span>
                WhatsApp
              </span>
              {/* <span className="d-none d-md-flex">
                Click to WhatsApp
              </span> */}
            </a>
          </div>
        </Col>
      </Row>
    </Container>
    </section>
  );
};

export default ButtonGroup;
